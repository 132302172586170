*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
   margin: 0;
    font-family: poppins,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #48465b;
    text-align: left;
} 
                        /* Container class */
.custom-container {
    display: flex;
    flex-direction: column;
    /*justify-content: center; */
    align-items: center;  
    padding: 5px;
    min-height: 100vh;
    margin-left: 290px; /* pushing container to right to avoid overlapping with the menu */
    transition: margin-left 0.3s;
}
.custom-container  h1{
    color: rgb(67, 63, 63);
    align-self: flex-start;
    padding: 20px;
}
                            /* Menu styles */
.icon-size {
    width: 20px; 
    height: 20px;
}

.custom-side-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    height: 100vh;
    /* background-color: rgb(7,6,31, 0.92); */
    background-color: #1e1b10;
    transition: width 0.3s;
    z-index: 9999;
}
.custom-side-menu ul{
    padding-left: 0;
}
/* For Hamburger menu */
.toggle-button {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    background-color: rgb(7,6,31, 0.92);
    color: #fff;
    text-align: center;
    padding: 10px;
    height: 60px;
    cursor: pointer;
    align-items: center;
    z-index: 2;
    display: none;
    justify-content: flex-end;
}
.toggle-button .icon{
    color: aliceblue;
    margin-right: 10px;
    margin-left: auto;
}
.toggle-button .logo{ /*Billionaire club collc */
    font-size: 20px;
    margin-left: 20px;
    margin-right: auto;
    text-decoration: none;
}
 /* menu header with the billionaire logo */
.custom-menu-header {
    color: aliceblue;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
    border-bottom: 1px solid rgb(255,255,255,0.2);
}
.menu-item {
    list-style-type: none;
    color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    position: relative; /* Added for dropdown menu positioning */
    transition: background-color 0.3s;
}
.label{
    font-weight: bold; 
    white-space: nowrap;
}
.menu-item a{
    text-decoration: none;
   color: aliceblue;
}
.menu-item svg,
.menu-item a svg{
    /* color: rgb(67, 93, 220); */
    color: #b08c07;

}

.menu-item ul.bc-dropdown-menu {
    display: none;
    padding: 0;
    margin: 0;
    margin-left: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%; 
}
/* symbol for drop down menu*/
.dropdown-symbol {
    transition: transform 0.3s;
    margin-left: auto;
    margin-right: 10px;
    color: aliceblue;
}
.menu-item .menu-label{ /*Trying to ensure the label is displayed correctly */
    display: flex;
    justify-content: space-between;
    width: 100%; 
    white-space: nowrap;
    text-overflow: hidden;
}
.menu-item .menu-single{
    display: inline-flex;
    justify-content: flex-start;
    width: 100%;
}
.menu-item ul.bc-dropdown-menu li{
    width: 100%;
    margin: 0;
}

.menu-item ul.bc-dropdown-menu li a {
    color: #fff;
    padding: 5px 10px;
    margin: 0;
    text-decoration: none;
    display: block;
}
.menu-item ul.bc-dropdown-menu li:hover{
    background-color: rgb(255,255,255, 0.1);
}

/* Display the drop down menu block */
.menu-item.open ul.bc-dropdown-menu {
    display: flex;
}

.menu-item:hover, .menu-item.active {
    background-color: rgb(12,6,29, 0.85);
    border-left: 3px solid white;
    padding-left: calc(10px - 3px);
}
.menu-item:hover svg, .menu-item.active svg,
.menu-item:hover a svg, .menu-item.active a svg{
    color: white;
}

/* icon margin from the .label item*/
i.icon {
    font-style: normal;
    margin-right: 10px;
}

.menu-item a:active .dropdown-symbol {
    transform: rotate(90deg);
}

.toggle-view {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
}


/* Icon-only view width */
.custom-side-menu.icon-only {
    width: 60px;
}

/* Icon-only view width on hover */
.custom-side-menu.icon-only:hover {
    width: 280px;
}
/* Icon-only view */
.custom-side-menu.icon-only .label, 
.custom-side-menu.icon-only .dropdown-symbol {
    display: none;
}
.custom-side-menu.icon-only:hover .label,
.custom-side-menu.icon-only:hover .dropdown-symbol {
    display: inline;
}

/* Search styling */
.search-out{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 30px;
    padding-top: 10px;
}
/* Sign Out In CSS */
.signout-button{
    background-color: #1c1a23;
    padding: 8px 10px;
    text-decoration: none;
    color: black ;
    border-radius: 5px;
    text-align: center;
    /* opacity: 0.8;x */
 }
 
 .signout-button a{
     text-decoration: none;
     color: black ;
 }
 
 /* .signout-button:hover {
     background-color: #1c1a23;
     opacity: 1;
 } */
 
.search-box {
    flex: 0.65;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: auto 10px;
    max-width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: fixed;
}

.search-input {
    flex: 1;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px 0 0 5px;
}

.search-button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    transition: background-color 0.3s;
}

.search-button:hover {
    background-color: #0056b3;
}
/* Hamburger icon */
.hamburger{
    display: block;
    cursor: pointer;
}
.bar{
    display: block;
    width: 25px; 
    height: 3px;
    margin:5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: aliceblue;
}


/* For screens less than 768px */
@media only screen and (max-width: 888px) {
    .hamburger.active .bar:nth-child(2){
        opacity: 0;
    }
    .hamburger.active .bar:nth-child(1){
       transform: translateY(8px) rotate(45deg);
    }
    .hamburger.active .bar:nth-child(3){
        transform: translateY(-8px) rotate(-45deg);
    }
    .toggle-button {
        display: flex;
    }

    .custom-side-menu {
        margin-top: 60px;
        width: 0;
        overflow: hidden;
    }

    .custom-side-menu.open {
        width: 100%;
    }
    .custom-side-menu.icon-only:hover,
    .custom-side-menu.icon-only {
        width: 0;
    }
    .custom-menu-header,
    .custom-menu-header .label, .custom-menu-header .toggle-view{
        /* display: none; */
    }
    .custom-container {
        margin-left: 0; /* remove the margin on mobile */
        padding: 10px; /* reduce the padding on mobile */
        margin-top: 60px;
    }
    .search-out {
        flex-direction: column;  /* stack children vertically */
        align-items: stretch;    /* make children stretch to full width */
        display: none;
    }

    .search-box {
        order: 2;   /* Make search box appear second */
        width: 100%; 
        margin-top: 10px; /* Add some space below the sign out button */
    }

    .signout-button {
        width: 20%; 
        order: 1;   /* Make sign out button appear first */ 
        text-align: center;
    }

    .search-input, 
    .search-button {
        padding: 12px;  /* Increase padding for better touch usability */
    }

    .signout-button {
        padding: 10px 0;  /* Increase vertical padding for better touch usability */
    }
}

/* mobile icons */
.mobile-icons {
    display: none;
  }


  @media only screen and (max-width: 888px) {

     /* mobile icons style */
     .mobile-icons {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        /* background-color: #1c133a; */
        background-color: #1e1b10;
        border-top: 1px solid #b08c07;
        padding: 5px 0;
        z-index: 999;
      }
      
      .mobile-icons a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; /* Center both icon and text */
        text-decoration: none;
      }
      
      .mobile-icons .icon-size {
        width: 25px; 
        height: 25px;
      }
      
      .mobile-icons a i,
      .mobile-icons a span {
        color: gold;
        font-size: x-small;
      }
  }

.label{
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}





@media only screen and (max-width: 425px) {
    .smallscreenhide{
        display: none;

    }

}
