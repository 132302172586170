.list-search-user-chat{
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 77%;
    min-height: 77%;
    /* max-height: 430px; */
    overflow-y: auto;
}

.user-chat{
    display: flex;
    gap: 15px;
    padding: 10px 15px;
    border-radius: 10px;
    cursor: pointer;

}


.user-chat-img{
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.user-chat-img img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.username{

    text-transform: capitalize;
   
}


.user-chat:hover{
    background-color: #0d6efd;
    opacity: 1;
    color: white;
}

.online{
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
    font-size: 20px;
    background-color: #009975;
    border-radius: 50%;
    border: 3px solid #ffffff;
    box-shadow: 1px 1px 15px -4px #000;
}

.head-chat-message-user{
    margin-top: 70px;

}

@media only screen and (min-width: 1024px) {
    .tabletscreen{
        margin-top: 70px;
    }


}


@media only screen and (max-width: 768px) {
    .head-chat-message-user{
        margin-top: 0;
    }
 


}


@media only screen and (max-width: 425px) {
    .smllmargin{
        margin-top: 130px;
    }
   

}



